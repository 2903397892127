<template>
  <el-dialog v-model="dialogVisible" title="合同验签列表" :close-on-click-modal="false" width="1020px">
    <div class="confirmbox">
      <el-table :data="state.Notice" class="mb-20" :header-cell-style="{
        background: '#EAEAEA',
        color: config.table.color,
        fontWeight: config.table.fontWeight,
        fontSize: config.table.fontSize,
      }" row-key="id">
        <el-table-column :align="'center'" prop="userName" label="签署主体" />
        <el-table-column :align="'center'" prop="signTime" label="签署时间" />
        <el-table-column :align="'center'" prop="signAuth" label="签名是否有效" />
        <!-- 是否被篡改、证书颁发单 -->
        <!-- <el-table-column :align="'center'" prop="signAllDoc" label="签名是否覆盖全文" />
        <el-table-column :align="'center'" prop="signName" label="签名名称" /> -->
      </el-table>
    </div>
    <!-- <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="导入" CSStype=2 @buttonclick="submit()"
          v-model:loading="dialogloading" />
        <oabutton class="searcML" width='120' height='40' title="重新上传" CSStype=3 @buttonclick="dialogVisible = false" />
      </span>
    </template> -->
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  Notice: []
})
const getData = ((data) => {
  // signAllDoc 签名是否覆盖全文
      // signAuth 签名是否有效
      //  signName 签名名称
  data.forEach(item => {
    item.signAllDoc=item.signAllDoc?'覆盖':'未覆盖'
    item.signAuth=item.signAuth?'有效':'失效'
  });
  state.Notice = data
  dialogVisible.value = true
})
defineExpose({
  getData
});
</script>
<style lang="scss" scoped >
.confirmbox {
  ::v-deep .el-table .el-table__cell {
    height: 50px;
  }
}
</style>